<template>
  <div class="Footer">
    <h1>Kies een gebouw om verder te gaan</h1>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.Footer {
  color: #fff;
  background-color: #30988a;
  display: inline-flex;
}

h1 {
  margin: auto;
}
</style>